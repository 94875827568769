.node-styles {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    background-color: white;
    /* background-color: lightgrey; */
    border: solid 1px black;
    border-radius: 5%;
    cursor:pointer;
    opacity: .8;
  }