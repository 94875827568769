.carousel-root {
    /* Add desired styles for the carousel container */
  }
  
  .carousel .slide {
    /* Add desired styles for each slide */
  }
  
  /* .carousel .slide img {
    width: 100%;
    height: auto;
    border: 2px solid #000;
    box-sizing: border-box;
  } */
  .carousel .slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 2px solid #000;
    box-sizing: border-box;
  }