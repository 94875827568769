


.title {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: -1;
    height:100%;
    width:100%;
}

.edit-arrow {

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-right: 100px;
    margin-top: 15px;
}

.profile-pic-arrow {
    position: absolute;
    top: 25%;
    right: 25%;
    transform: translate(10px, 10px);
}


.tree-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: -1;
    height:100vh;
    width:100vw;
    /* position: relative; */
}

/* some copy and paste border code below: */
/* body{
    background:#fff;
    font-family: 'Bangers', cursive;
    font-size: 25px;
  } */
  .someparams {
   
  }
  .tree-border{
    margin:10px;
    width:500px;
    height:600px;
    background:#fff;
    border: solid black;
    border-color: black;
    border-width: 4px;
    float:center; 
    /* z-index: -1; */
  }
  .tree-border-more{
    margin:25px;
    width:500px;
    height:600px;
    background:#fff;
    border: solid black;
    border-color: black;
    border-width: 4px;
    float:center; 
    /* overflow: auto; */
    /* transition: ease all .5s; */
    z-index: -1;
  }
  .tree-border-full{
    /* margin:100vh; */
    width:100vw;
    height:100vh;
    background:#fff;
    /* border: solid black; */
    /* border-color: black; */
    /* border-width: 4px; */
    float:center; 
    z-index: 1;
  }
  html, body {
    height: 100%;
    width:100%;
  }
  /* .tree-border-full{
    margin:25px;
    width:1000px;
    height:600px;
    background:#fff;
    border: solid black;
    border-color: black;
    border-width: 4px;
    float:center; 
    resize: both;
    overflow: auto;
    z-index: 1;
  } */
  /* profile styles */
  .custom-iframe {
    min-height: 685px;
    max-height: 100%; /* Maximum height */
    width: 100%; /* 100% width */
  }

  .profile-border{
    margin:10px;
    width:500px;
    height:855px;
    background:#fff;
    /* background-image: url('/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/paint-3.jpeg'); */
    /* background-image: url('/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/paint-4.jpeg'); */
    /* border: solid white; */
        border-color: white;
    border-width: 4px;
    border-radius: 5px;
    box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */

  }

  .profile-text {
    margin:15px;
    max-width:600px;
  }

  /* community wall styles */
  .wall-create-post{
    margin:10px;
    width:90%;
    /* height:900px; */
    /* max-height: 1200px; */
    /* background:#fff; */
    /* background-image: url('/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/paint-3.jpeg'); */
    /* background-size: contain; */
    border: solid black;
    border-color: black;
    border-width: 0px;
    border-radius: 0.5%;
    overflow-y: scroll;
  }
  .wall-create-post-small{
    margin:10px;
    width:90%;
    /* height:90%; */
    /* height: 90%; */
    /* background:#fff; */
    /* background-image: url('/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/paint-2.jpeg'); */
    /* background-size: contain; */

    border: solid black;
    border-color: black;
    border-width: 0px;
    border-radius: 0.5%;
    overflow-y: scroll;
  }
  .wall-past-posts{
    margin:0px;
    width:90%;
    height:200px;
    background:#fff;
    border: solid black;
    border-color: black;
    border-width: 0px;
    border-radius: 0.5%;
  }
  .wall-post{
    margin:5px;
    /* width:80%; */
    width:550px;
    height:50px;
    background:#fff;
    border: solid black;
    border-color: black;
    border-width: 1px;
    border-radius: 0.5%;
  }
  @media (min-width: 0px) {
    .wall-post{
      margin:5px;
      width:200px;
      height:50px;
      background:#fff;
      border: solid black;
      border-color: black;
      border-width: 1px;
      border-radius: 0.5%;
    }
  }
  @media (min-width: 400px) {
    .wall-post{
      margin:5px;
      width:300px;
      height:50px;
      background:#fff;
      border: solid black;
      border-color: black;
      border-width: 1px;
      border-radius: 0.5%;
    }
  }
  @media (min-width: 500px) {
    .wall-post{
      margin:5px;
      width:400px;
      height:50px;
      background:#fff;
      border: solid black;
      border-color: black;
      border-width: 1px;
      border-radius: 0.5%;
    }
  }
  @media (min-width: 600px) {
    .wall-post{
      margin:5px;
      width:500px;
      height:50px;
      background:#fff;
      border: solid black;
      border-color: black;
      border-width: 1px;
      border-radius: 0.5%;
    }
  }
  @media (min-width: 700px) {
    .wall-post{
      margin:5px;
      width:600px;
      height:50px;
      background:#fff;
      border: solid black;
      border-color: black;
      border-width: 1px;
      border-radius: 0.5%;
    }
  }
  @media (min-width: 800px) {
    .wall-post{
      margin:5px;
      width:700px;
      height:50px;
      background:#fff;
      border: solid black;
      border-color: black;
      border-width: 1px;
      border-radius: 0.5%;
    }
  }




  .groupPasswordPic {
    position: relative;
    width:100vw;
    /* height:100vh; */
    margin-bottom: -5px;
    padding: 0;
    z-index: -1;
  }
  .text-overlay {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: black;
    z-index: 2;
  }

  /* the below media queries work... but might try something else */

  /* @media (min-width: 600px) {
    .tree-border-more {
        margin:25px;
        width:600px;
        height:600px;
        background:#fff;
        border: solid black;
        border-color: black;
        border-width: 4px;
        float:center; 
        z-index: 1;
    }
  } */
  /* @media (min-width: 700px) {
    .tree-border-more {
        margin:25px;
        width:625px;
        height:600px;
        background:#fff;
        border: solid black;
        border-color: black;
        border-width: 4px;
        float:center; 
        z-index: 1;
    }
  } */
  /* @media (min-width: 750px) {
    .tree-border-more {
        margin:25px;
        width:600px;
        height:600px;
        background:#fff;
        border: solid black;
        border-color: black;
        border-width: 4px;
        float:center; 
        z-index: 1;
    }
  } */
  /* @media (min-width: 800px) {
    .tree-border-more {
        margin:25px;
        width:775px;
        height:600px;
        background:#fff;
        border: solid black;
        border-color: black;
        border-width: 4px;
        float:center; 
        z-index: 1;
    }
  } */
  /* @media (min-width: 850px) {
    .tree-border-more {
        margin:25px;
        width:825px;
        height:600px;
        background:#fff;
        border: solid black;
        border-color: black;
        border-width: 4px;
        float:center; 
        z-index: 1;
    }
  } */
  @media (min-width: 800px) {
    .tree-border-more {
        margin:25px;
        width:750px;
        height:600px;
        background:#fff;
        border: solid black;
        border-color: black;
        border-width: 4px;
        float:center; 
        z-index: -1;
    }
  }
  @media (min-width: 950px) {
    .tree-border-more {
        margin:25px;
        width:900px;
        height:600px;
        background:#fff;
        border: solid black;
        border-color: black;
        border-width: 4px;
        float:center; 
        z-index: -1;
    }
  }
  @media (min-width: 1100px) {
    .tree-border-more {
        margin:25px;
        width:1050px;
        height:600px;
        background:#fff;
        border: solid black;
        border-color: black;
        border-width: 4px;
        float:center; 
        z-index: -1;
    }
  }
  

  

  .content {
    width: 100%;
    height: 100%;
  }

  .full-screen {

  }

  .box{
    margin:25px;
    width:510px;
    height:610px;
    background:#fff;
    border: solid black;
    border-color: black;
    float:left; 
    z-index: -1;
  }
  
  .box1{
    /* border-width: 3px 4px 3px 5px; */
    border-width: 4px;
    /* border-radius:95% 4% 92% 5%/4% 95% 6% 95%; */
    border-radius: 3%;
    transform: rotate(0deg);
    z-index:-1;
  }
  .box2{
    width:350px;
    border-width: 3px 3px 5px 5px;
    border-radius:4% 95% 6% 95%/95% 4% 92% 5%;
    transform: rotate(-2deg);
  }
  
  .box3{
    border-width: 5px 3px 3px 5px;
    border-radius:95% 4% 97% 5%/4% 94% 3% 95%;
    transform: rotate(2deg);
  }
  
  .oddboxinner{
    margin:15px;
    transform: rotate(-2deg);
  }
  .evenboxinner{
    transform: rotate(2deg);
    margin:15px;
    padding:0 5px;
    float:right;
    background:#ddd;
    border:1px solid #222;
    box-shadow:3px 3px 0 #222;
  }

  /* DROP DOWN STYLES >>>>>>>>>>>>>>>>>>>>>*/

  .dropdown {
    position: relative;
    display: inline-block;
    z-index:1;

  }

  .dropdown-item {
    list-style: circle inside;
    padding: 5px;
    cursor: pointer;
    display:flex;
    justify-content: left;
    z-index: 1;
    background-color: white;
    border-radius: 1rem;
    margin: 1rem;
    /* margin-bottom: 15px; */
    z-index:1;
    /* background-image: linear-gradient(45deg, #0074D9 25%, transparent 25%, transparent 75%, #0074D9 75%), linear-gradient(-45deg, #0074D9 25%, transparent 25%, transparent 75%, #0074D9 75%);
    /* background-size: 50px 50px;
    background-position: 0 0, 25px 25px; */
  
  }
  
  .dropdown-item:hover {
    /* background-color:none; */
    /* text-decoration: underline; */
    z-index:1;
    /* border-bottom:3px solid pink; */
  }

  .dropdown-list {
      /* position: absolute;
    top: 100px;
    left: 50px;
    width: 150px; */
    /* margin:"20px"; */
    z-index:1;
    height:100%;
    background-color:white;
    padding: 25px;
    border: 2px solid black;
    /* border-top: none; */
    border-radius: 0.5rem;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
    }

  .for-dropdown {
    position: relative;
    top: 5px;
    left: 70px;
    width: 25%;
  }
  /*  */
  .sdfg {
    position: relative;
    width: 400px;
    height: 200px;
    border: 3px solid red;
  }
  
 .sdfgdfgdf {
    position: absolute;
    right: 0;
    width: 100px;
    height: 120px;
    border: 3px solid blue;
  } 

  /* photos styles */
  .photo-border{

    margin:10px;
    width:500px;
    height:400px;
    background:black;
    border: solid black;
    border-color: black;
    border-width: 4px;
    /* float:center;  */
    /* z-index: -1; */
  }
  .fit-border {
    width: 500px;
    height: 400px;
    object-fit: contain;
    border:none;
    box-sizing: border-box;
  }
  .photo-border-large{

    margin:10px;
    width:800px;
    height:700px;
    background:black;
    border: solid black;
    border-color: black;
    border-width: 4px;
  }
  .fit-border-large {
    width:800px;
    height:700px;
    object-fit: contain;
    border:none;
    box-sizing: border-box;
  }

  .center-stuff {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* z-index: -1; */
  }
  .center-stuff-wall {
    display: flex;
    flex-direction: column;
    align-items: normal;
  }
  .center-column {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
    /* z-index: -1; */
  }
  /* .user-posts {
  border: 1px solid #000;
  padding: 2%;
  margin-bottom: 2px;
  width: 90%;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  }
  .user-posts:hover {
    overflow: auto;
    text-overflow: inherit;
  } */

  /* .user-posts {
    border: 1px solid #000;
    padding: 2%;
    margin-bottom: 2px;
    width: 90%;
    height: 50px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    cursor: pointer;
  }
  
  .user-posts:hover {
    max-height: none;
  } */

  /*  */

  .user-posts {
    /* border: 1px solid #000; */
    /* border-radius: 5px; */
    padding: 2%;
    /* margin-bottom: 2px; */
    /* width: 90%; */
    /* height: 50px; */
  overflow: auto;
  text-overflow: ellipsis;
  word-wrap: break-word;
  /* cursor:pointer; */
  /* margin-left: 3%; */
  }
  .user-replies {
    border: 3px solid white;
    border-radius: 5px;
    /* box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.1);  */
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1); 
    padding: 2%;
    margin-bottom: -10px;
    width: 60%;
    height: 30px;
  overflow: auto;
  will-change: transform;
  /* overflow-y: initial; */
  text-overflow: ellipsis;
  word-wrap: break-word;
  /* cursor:pointer; */
  margin-left: 30%;
  background-color: white;
  z-index:1;
  }


  /* speech-bubble for sign up */
  .speech-bubble-sign {
    position: relative;
    /* max-width: 700px; */
    margin-top: 30px;
    margin-bottom:20px;
    margin-right:20%;
    margin-left:20%;
  }
  
  .speech-bubble-sign > p {
    position: relative;
    background-color: white;
    padding: 15px;
    border: 3px solid black;
    border-radius: 10px;
    text-align: center;
  }
  
  .speech-bubble-sign > p::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 20%;
    transform: translateX(-50%);
    border-style: solid;
    /* border-width: 20px 20px 0; */
    /* border-color: black transparent transparent transparent; */
    border-width: 0 20px 20px; /* Change the order of values */
    border-color: transparent transparent black transparent; /* Adjust the order of colors */
    z-index: 1;
  }
  
  .speech-bubble-sign > p::after {
    content: '';
    position: absolute;
    top: -17px;
    left: 20%;
    transform: translateX(-50%);
    border-style: solid;
    /* border-width: 18px 18px 0px; */
    /* border-color: white transparent transparent transparent; */
    border-width: 0px 18px 18px; /* Change the order of values */
    border-color: transparent transparent white transparent;
    z-index: 2;
  }
  /*  */
  /* sign up wide version: */
  .speech-bubble-sign-wide {
    /* position: absolute; */
    /* top:45%; */
    /* right:5%; */
    max-width: 300px;
  }
  
  .speech-bubble-sign-wide > p {
    position: relative;
    background-color: white;
    padding: 15px;
    border: 3px solid black;
    border-radius: 10px;
    text-align: center;
  }
  
  .speech-bubble-sign-wide > p::before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 20%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 20px 20px 0;
    border-color: black transparent transparent transparent;
    z-index: 1;
  }
  
  .speech-bubble-sign-wide > p::after {
    content: '';
    position: absolute;
    bottom: -17px;
    left: 20%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 18px 18px 0;
    border-color: white transparent transparent transparent;
    z-index: 2;
  }
  /*speech bubble for harriet  */
  .speech-bubble-h {
    position: relative;
    /* max-width: 200px; */
    margin-top: 15px;
    margin-bottom:20px;
  }
  
  .speech-bubble-h > p {
    position: relative;
    background-color: white;
    padding: 15px;
    border: 3px solid black;
    border-radius: 10px;
    text-align: center;
  }
  
  .speech-bubble-h > p::before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 20%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 20px 20px 0;
    border-color: black transparent transparent transparent;
    z-index: 1;
  }
  
  .speech-bubble-h > p::after {
    content: '';
    position: absolute;
    bottom: -17px;
    left: 20%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 18px 18px 0;
    border-color: white transparent transparent transparent;
    z-index: 2;
  }
  /* speech bubble for Robert */
  .speech-bubble-r {
    position: relative;
    max-width: 350px;
    margin-top: 15px;
    /* margin-bottom:20px; */
    z-index: -1;
  }
  
  .speech-bubble-r > p {
    position: relative;
    background-color: white;
    padding: 15px;
    border: 3px solid black;
    border-radius: 10px;
    text-align: center;
    z-index: -1;
  }
  
  .speech-bubble-r > p::before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 20%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 20px 20px 0;
    border-color: black transparent transparent transparent;
    z-index: -1;
  }
  
  .speech-bubble-r > p::after {
    content: '';
    position: absolute;
    bottom: -17px;
    left: 20%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 18px 18px 0;
    border-color: white transparent transparent transparent;
    z-index: -1;
  }
  /* speech bubble for CS */
  .speech-bubble-cs {
    position: relative;
    max-width: 350px;
    margin-top: 15px;
    /* margin-bottom:20px; */
  }
  
  .speech-bubble-cs > p {
    position: relative;
    background-color: white;
    padding: 15px;
    border: 3px solid black;
    border-radius: 10px;
    text-align: center;
  }
  
  .speech-bubble-cs > p::before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 20%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 20px 20px 0;
    border-color: black transparent transparent transparent;
    z-index: 1;
  }
  
  .speech-bubble-cs > p::after {
    content: '';
    position: absolute;
    bottom: -17px;
    left: 20%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 18px 18px 0;
    border-color: white transparent transparent transparent;
    z-index: 2;
  }
  /* dfgdfgfd */
  .speech-bubble-hhh {
    position: absolute;
    top:180px;
    background-color: white;
    padding: 10px;
    /* border: 7px solid #80b6da; */
    /* border: 7px solid #0078d0; */
    border: 5px solid black;
    /* 0078d0  */
    border-radius: 10px;
    max-width: 200px;
    text-align: center;
  }
  .speech-bubble-hhh::before {
    content: '';
    position: absolute;
    bottom: -20px; 
    left: 20%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 20px 20px 0;
    border-color: black transparent transparent transparent;
    z-index: 1;
  }

  .speech-bubble {
    position: absolute;
    top:150px;
    background-color: white;
    padding: 10px;
    /* border: 7px solid #80b6da; */
    /* border: 7px solid #0078d0; */
    border: 5px solid black;
    /* 0078d0  */
    border-radius: 10px;
    max-width: 200px;
    text-align: center;
  }
  .speech-bubble::before {
    content: '';
    position: absolute;
    bottom: -20px; 
    left: 20%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 20px 20px 0;
    border-color: black transparent transparent transparent;
    z-index: 1;
  }
  /* speech bubble for UPLOAD */
  .speech-bubble-upload {
    position: absolute;
    top:275px;
    background-color: white;
    padding: 10px;
    border: 7px solid black;
    border-radius: 10px;
    max-width: 200px;
    text-align: center;
  }
  .speech-bubble-upload::before {
    content: '';
    position: absolute;
    bottom: -20px; 
    left: 20%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 20px 20px 0;
    border-color: black transparent transparent transparent;
    z-index: 1;
  }
  
  /*  */
  .center-stuff-agenda {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin:10px;
    /* z-index: -1; */
  }
  .center-stuff-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* z-index: -1; */
    margin:5px;
  }

  .center-image {
    position: absolute;
    top: 50%;
    left: 50%;
    /* transform: translate(-50%); */
    border: none;
    border-radius: 5%;
    /* z-index: -1; */
  }
  /* .top-element {
    align-self: flex-start;
  } */

  /* React SLick stuff */

  .slick-border {
    margin: 10px;
    /* position: absolute;
    top:0; */
    width: 600px;
    max-width: 90vw;

    /* height: 500px; */
    /* max-width:90vw; */
    /* max-height:90vh; */
    /* width: 100%;
    height: 100%; */
    /* background: black; */
    /* border: 4px solid black; */
    border-radius: 5px;
  }
  
  .fit-image {
    /* width: 100%;
    height: 100%; */

    width: 600px;
    max-width: 90vw;
    /* height: 500px; */
    /* height:100vh; */

    /* width: 100%; */
    /* height: 500px; */
    object-fit: contain;
    border: none;
    box-sizing: border-box;
  }
  /* enlarged: */
  .slick-border-larger {
    position: absolute;
    top:0;
    width: 100%;
    height: 100%;
    background: black;
    border: 4px solid black;
  }
  
  .fit-image-larger {
    width: 100%;
    object-fit: contain;
    border: none;
    box-sizing: border-box;
  }
  /* full screen logic (as opposed to just bigger) */
  
  /* grid layout for "albumList.js */
  .album-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
    grid-gap: 10px;
    margin:20px;
    margin-top: 35px;
    -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
          page-break-inside: avoid; /* Firefox */
               break-inside: avoid; /* IE 10+ */

}
.object-fit{
  object-fit: initial;
  /* margin:10px; */
  
}

/* images in the album list */
.grid-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
    object-position: top; /* This will ensure the top of the image is always in frame */
  /* border-radius: 5px; */
  cursor:pointer;
  transition: transform 0.3s ease; /* Add a smooth transition effect */
  transform: scale(1.05);
}
.grid-image:hover {
  transform: scale(1.10);
}

/* object-fit: initial; */
.center-grid-stuff {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;

  /* background-color: rgb(241, 251, 255);
  border: solid 1px rgb(241, 251, 255); */
  /* background-image: url('/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/paint-5.jpeg'); */
  background-size: 100%;

  background-color: white;
  /* background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"><rect width="10" height="10" fill="black"/><line x1="0" y1="10" x2="10" y2="0" stroke="white" stroke-width="1"/></svg>'); */
  /* background: linear-gradient(to top right, black 50%, white 50%); */
  /* border: solid 1px white; */
  /* z-index: -1; */
  /* border-radius: 5px; */
  cursor:pointer;
  opacity: 1;
  /* transition: right 1s ease-in-out; */
  /* transform: translateX(1000%); */
  /* transition: transform 2s ease-in; */
  transition: opacity 1s ease-in-out;
}
.center-grid-stuff:hover {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;

  /* background-color: rgb(241, 251, 255);
  border: solid 1px rgb(241, 251, 255); */
  background-image: url('/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/paint-5.jpeg');
  background-size: 100%;
  background-color: white;
  /* background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"><rect width="10" height="10" fill="black"/><line x1="0" y1="10" x2="10" y2="0" stroke="white" stroke-width="1"/></svg>'); */
  /* background: linear-gradient(to top right, black 50%, white 50%); */
  /* border: solid 1px white; */
  /* z-index: -1; */
  border-radius: 5px;
  cursor:pointer;
  opacity: 1;
  /* transition: right 1s ease-in-out; */
  /* transform: translateX(1000%); */
  /* transition: transform 2s ease-in; */
  transition: opacity 1s ease-in-out;
}

.center-grid-stuff:nth-child(2):hover {
  background-image: url('/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/paint-10.jpeg');
  background-size: 100%;
}
.center-grid-stuff:nth-child(3):hover {
  background-image: url('/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/paint-3.jpeg');
  background-size: 100%;  

}
.center-grid-stuff:nth-child(4):hover {
  background-image: url('/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/paint-3.jpeg');
  background-size: 120%;
}
.center-grid-stuff:nth-child(5):hover {
  /* transform: rotate(50deg); */
  background-image: url('/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/paint-7.jpeg');
  background-size: 100%;
}
.center-grid-stuff:nth-child(6):hover {
  background-image: url('/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/paint-9.jpeg');
  background-size: 100%;
}
.center-grid-stuff.visible {
  /* When the 'visible' class is added, bring the div into view */
  transform: translateX(0);
  opacity:0.9;
}
.center-grid-stuff.visible:hover {
  /* When the 'visible' class is added, bring the div into view */
  transform: translateX(0);
  opacity:1;
  transition: opacity 0s ease-in-out;

}
.history-grid-stuff {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 250px;
  max-width:100%;
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.1); 
  background-color: white;
  cursor:pointer;
  opacity: 1;
  overflow: hidden; /* Add this line to hide overflow */

}
.history-grid-stuff:hover .history-grid-icon {
  font-size: 60px;
}


/* toLogin page on albumList */
.center-login-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  max-width: 400px;
  /* background-color: rgb(241, 251, 255);
  border: solid 1px rgb(241, 251, 255); */

  
  background-color: white;
  border: solid 6px #0078d0;

  border-radius: 5px;
  /* cursor:pointer; */
  opacity: 1;
}

.countdown-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 350px;
  background-color: white;
  border: solid 6px #0078d0;
  border-radius: 5px;
  opacity: 1;
}

.stylized-cursive {
  font-family: cursive;
  font-size: 20px;
  font-weight: bold;
  color: purple;
  /* Add any other stylized properties you need */
}

.history-grid-icon {
  font-size: 50px;
  cursor: pointer;
  transition: font-size 0.3s ease; /* Add a smooth transition effect */
}

/* .wall-post-prompt {
  max-width: 600px;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  opacity: 1;
} */
.old-list-tag {
  height:50px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: grid;
  place-content: end; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  /* white-space: wrap; */
  cursor: pointer;
  font-family: 'Tahoma', sans-serif;
  font-size: 18px;
  /* font-weight: bold; */
  padding: 5px;;
  text-align: center;
  color: #333; /* Adjust color as needed */
}
.list-tag {
  height:50px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: grid;
  place-content: end; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  /* white-space: wrap; */
  cursor: pointer;
  font-family: 'Tahoma', sans-serif;
  font-size: 18px;
  /* font-weight: bold; */
  padding: 5px;;
  text-align: center;
  color: #333; /* Adjust color as needed */
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
}

.new-list-tag {
  position: relative;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  font-family: 'Tahoma', sans-serif;
  font-size: 18px;
  padding: 5px;
  text-align: center;
  color: #333;
  border: 2px solid black;

}

.wall-name {
  /* cursor: pointer; */
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
  text-align: center;
  color: #333; /* Adjust color as needed */
}
.reply-name {
  /* cursor: pointer; */
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding: 0px;
  text-align: left;
  color: #333; /* Adjust color as needed */
}



.wall-post-prompt {
  max-width: 600px;
  padding: 10px;
  background-color: white; /* Light grayish background color */
  border: solid 3px none; /* Border color similar to your page's borders */
  border-radius: 8px; /* Soften the edges */
  /* box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.1);  */
  font-size: 16px; /* Adjust font size as needed */
  color: #333; /* Dark text color for readability */
  line-height: 1.5; /* Improve text readability with proper line height */
  outline: none; /* Remove the default focus outline */
  transition: border-color 0.3s ease-in-out; /* Smooth transition for border color */
}
.image-comment{
  margin-top: 12px;
  /* width: 500px; */
  width: 90%;
  height:100px;
  padding: 10px;
  background-color: white;
  /* border:black solid; */
  border-radius: 5px;
  opacity: 1;
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
}

/* activities/attractions underline: */
.the-a {
  color:black; 
   font-family:Helvetica Neue;
   font-size: 28px;
   position:relative;
   border-bottom:3px solid pink;
 }
 .the-a::before {
   content: '';
   position: absolute;
   bottom: -3px;
   left: 0;
   width: 100%;
   height: 3px;
   background: pink;
   border-radius: 0px; /* Adjust the radius to control the roundness */
   z-index: 1;
   box-shadow: 0 3px 5px pink; 
 }

/* titles with underlines */
.the-titles {
 color:black; 
  font-family:Helvetica Neue;
  font-size: 18px;
  position:relative;
  border-bottom:3px solid pink;
}
.the-titles::before {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background: pink;
  border-radius: 0px; /* Adjust the radius to control the roundness */
  z-index: 1;
  box-shadow: 0 3px 5px pink; 
}
.the-titles-hover {
  color:black; 
   font-family:Helvetica Neue;
   font-size: 18px;
   position:relative;
   border-bottom:3px solid white;
   /* height: 30px; */

 }
 .the-titles-hover:hover {
  border-bottom:3px solid pink;
 }
 .the-titles-hover:hover::before {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background: pink;
  border-radius: 0px; /* Adjust the radius to control the roundness */
  z-index: 1;
  box-shadow: 0 3px 5px pink; 
}

/* for photos */

/* For RT riley image in top left: */
.rt-the-container {
  position: absolute;
  top: 5px;
  left: 10px;
  width: 30vw; /* Adjust the width based on your requirements */
  height: 30vw; /* Adjust the height based on your requirements */
  z-index: -1;
}

.rt-container img {
  width: 28.5vw;
  /* height: 100%; */
  object-fit: contain; /* Adjust the object-fit property based on your image aspect ratio preference */
}

.sign-up {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background-color: white;
  border: solid 1px white;
  border-radius: 5px;
  /* cursor:pointer; */
  /* width:300px; */
  /* height:20px; */
  opacity: 1;
  margin-top: 20px;
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
}
.sign-up-info {
  /* display: flex;
  flex-direction: column; */
  /* align-items: center; */
  background-color: white;
  border: solid 1px white;
  border-radius: 5px;
  /* cursor:pointer; */
  width:300px;
  opacity: 1;
  /* margin-top: 10px; */
}

.group-password {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background-color: white;
  border: solid 1px white;
  /* border-radius: 5%; */
  /* cursor:pointer; */
  opacity: 0.8;
}
.group-password-pic {
  position: absolute;
  top: 25%;
  transform: translate(-50%, -50%);
}
.should-be {
  position: absolute;
  top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
  border: solid 1px white;
  border-radius: 5%;
  height:200px;
  width:200px;
}
/*  */
.check-email {
  /* position: absolute; */
  /* top:405px; */
  /* top:30%; */
  /* display: flex;
  flex-direction: column; */
  /* align-items: center; */
  background-color: white;
  border: solid 1px #0078d0;
  border-radius: 0%;
  /* cursor:pointer; */
  opacity: 1;
  margin-top: 10px;
}

.login-error {
  position: absolute;
  top:345px;
  /* display: flex;
  flex-direction: column; */
  /* align-items: center; */
  background-color: white;
  border: solid 1px white;
  border-radius: 5px;
  /* cursor:pointer; */
  opacity: 1;
  z-index: -1;
}

/* media queries for rt riley photo top left family tree: */
@media (max-width: 1050px) {
  .rt-container img {
    width: 15vw;
    /* height: 100%; */
    object-fit: contain; /* Adjust the object-fit property based on your image aspect ratio preference */
  }
}

.group-password-quote {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background-color: white;
  border: solid 1px black;
  border-radius: 5%;
  /* cursor:pointer; */
  opacity: .9;
  position: absolute;
  top:20%;
  right:20%;
}

/* .date-container {
  max-width: 200px;
  background-color: #f7f7f7;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  opacity:.8;
} */
.date-container {
  max-width: 200px;
  background-color: #f7f7f7;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 0px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  opacity: 0.8;
  position: relative;

  animation: bounce 2s ease-in-out, colorChange 2s linear forwards;


  /* animation: bounce 2s ease-in-out forwards; */
  /* animation: colorChange 2s linear forwards; */
  /* animation: pulse 2s ease-in-out infinite; */
  /* animation: shake 2s ease-in-out infinite; */
  /* animation: slideIn 2s ease-out forwards,colorChange 2s linear forwards; */
  /* animation: flash 2s linear infinite; */
  /* animation: blink 2s linear infinite; */
}

@keyframes rotateAndScale {
  0%, 100% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.8);
  }
}
@keyframes blink {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@keyframes fadeAndScale {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes jelly {
  0%, 100% {
    transform: scale(1);
  }
  25%, 75% {
    transform: scale(1.25);
  }
  50% {
    transform: scale(0.75);
  }
}
@keyframes swing {
  0%, 100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(15deg);
  }
}
@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}
@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  25%, 75% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
@keyframes colorChange {
  0% {
    background-color: #f7f7f7;
  }
  50% {
    background-color: #afffa4ef;
  }
  100% {
    background-color: #f7f7f7;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}
/* .date-container::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom: 2px solid #00ff00;
  box-shadow: 0px 0px 5px rgba(0, 255, 0, 0.5);
  animation: neonEffect 3s linear normal forwards;
} */

/* @keyframes neonEffect {
  0%, 100% {
    height: 0;
  }
  50% {
    height: 100%;
  }
} */


.quote-container {
  position: absolute;
  top: 20%;
  right: 5%;
  max-width: 300px;
  background-color: #f7f7f7;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  opacity:.9;
}

.quote-container-home {
  position: absolute;
  bottom: -300px;
  right: 5%;
  margin-left: 100px;
  max-width: 900px;
  background-color: #f7f7f7;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  opacity:.9;
}
.quote-container-wide {
  position: absolute;
  bottom: -20px;
  right: 5%;
  margin-left: 100px;
  max-width: 900px;
  background-color: #f7f7f7;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  opacity:.8;
}
.quote-container-wider {
  position: absolute;
  bottom: 0px;
  right: 5%;
  margin-left: 100px;
  max-width: 400px;
  background-color: #f7f7f7;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  opacity:.9;
}
.quote-container-group {
  position: absolute;
  bottom: 100px;
  right: 5%;
  margin-left: 100px;
  max-width: 400px;
  background-color: #f7f7f7;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  opacity:.9;
}

.signup-q-container {
  position: absolute;
  top: 20%;
  left: 5%;
  width: 300px;
  background-color: #f7f7f7;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  opacity:.9;
}
.signup-q-container- {
  position: fixed;
  top: 40%;
  /* left: 0%; */
  /* transform: translate(15%, -10%); */
  /* max-width: 600px; */
  background-color: #f7f7f7;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  opacity:.9;
}
.signup-q-container-below {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f7f7f7;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  opacity: 1;
  width:90%;
}

.quote {
  font-size: 18px;
  font-family: "Helvetica Neue";
  line-height: 1.4;
  margin: 1;
  flex: 1;  
  margin-left: 15px;
  margin-right: 15px;

}
/* alt home quote containers */
.alt-quote-container-home {
  position: absolute;
  /* bottom: 100px; */
  bottom: 30px;
  /* right: 5%; */
  margin: 10px;
  
  /* max-width: 900px; */
  background-color: #f7f7f7;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  opacity:.9;
}
.alt-quote-container-wide {
  position: absolute;
  bottom: 30px;
  right: 5%;
  margin-left: 100px;
  max-width: 900px;
  background-color: #f7f7f7;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  opacity:.9;
}
.alt-quote-container-wider {
  position: absolute;
  bottom: 250px;
  right: 5%;
  margin-left: 100px;
  max-width: 900px;
  background-color: #f7f7f7;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  opacity:.9;
}
/* .alt-quote-container-wider {
  position: absolute;
  bottom: 300px;
  right: 5%;
  margin-left: 100px;
  max-width: 400px;
  background-color: #f7f7f7;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  opacity:.9;
} */
/* login sign up prompot thing with BSHAnd thingy */
.prompt-container {
  position: absolute;
  top: 300px;
  right: 5%;
  margin-left: 100px;
  max-width: 400px;
  background-color: #f7f7f7;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  opacity:.9;
}
.cs-face-thing {
  position: absolute;
  top: 38%;
  /* left:43%; */
  /* right: 5%; */
  /* margin-left: 100px; */
  max-width: 400px;
  background-color: #f7f7f7;
  padding: 10px;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  opacity:1;
  border-radius: 5px;

}
/* login sign up thingy end */
.buttonStyle {
  background-color: rgb(33, 61, 112);
  color:white;
  /* padding: 1px; */
  border-radius: 5px;
  cursor: pointer;
  height:20px;
  width:100px;
  margin-bottom:3px;
}

.underlineSpan:hover {
  cursor: pointer;
  text-decoration: underline;
}


/* @media (max-width: 1100px) {
  .rt-container img {
    width: 80%;
    height: 80%;
    object-fit: contain; 
  }
} */

/* CLOUD THINGS (testing) */

.cloud{ 
  position: absolute;
  top:300px;
  right:100px;
  /* margin-top:70px; */
  width:350px;
  height:120px;
  /* background:#ECEFF1; */
  background:white;
  box-shadow: 10px 10px rgba(0,0,0,0.2);
  border-radius:100px;
  z-index: 1;
}
.cloud-first{ 
  position: absolute;
  top:240px;
  right:300px;
  /* margin-top:70px; */
  width:100px;
  height:100px;
  /* background:#ECEFF1; */
  background:white;
  /* box-shadow: 10px 10px rgba(0,0,0,0.2); */
  border-radius:100px;
  /* z-index: -1; */
}
.cloud-second{ 
  position: absolute;
  right:150px;
  top:200px;
  /* margin-top:70px; */
  width:180px;
  height:180px;
  /* background:#ECEFF1; */
  background:white;
  /* box-shadow: 10px 10px rgba(0,0,0,0.2); */
  border-radius:100px;
  /* z-index: -1; */
}
.text-inside-cloud{ 
    border-radius: 150px;
    display: inline-block;
    padding: 30px; 
}
/* .cloud::after, .cloud::before{
   content:"";
   position:relative;
   display:inline-block;
   background:inherit;
   border-radius:inherit;
}
.cloud::after{
   width:100px;
   height:100px;
   top:-435px;
   left:50px;
}
.cloud::before{
   width:180px;
   height:180px;
   top: -70px;
   left:130px;
} */

/* .custom-file-input {
  display: inline-block;
  padding: 10px;
  width:150px;
  background-color: #ccc;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
} */
.custom-file-input {
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  padding: 10px;
  width: 150px;
  background-color: #0078d0; /* A shade of blue */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  /* font-weight: bold; */
  transition: background-color 0.3s ease; /* Smooth color transition */
}

.custom-file-input:hover {
  background-color: #2980b9; /* Darker shade of blue on hover */
}
.selected-files {
  margin-top: 10px;
}

.question {
  width: 90%;
  padding: 10px;
  font-family: 'Helvetica', sans-serif;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease-in-out;
}

/* Style for the textarea when focused */
.question:focus {
  border-color: #0078d0; /* Change border color on focus */
  outline: none; /* Remove default focus outline */
}

/* Style for the placeholder text */
.question::placeholder {
  color: #999; /* Placeholder text color */
}

.article-content {
  width: 90vw;
  height:90vh;
  padding: 10px;
  font-family: 'Helvetica', sans-serif;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease-in-out;
}

/* Style for the textarea when focused */
.article-content:focus {
  border-color: #0078d0; /* Change border color on focus */
  outline: none; /* Remove default focus outline */
}

/* Style for the placeholder text */
.article-content::placeholder {
  color: #999; /* Placeholder text color */
}

/* Light button thing */

.load-more-button {
  margin:5px;
  font-size: 15px;
  cursor: pointer;
}

/* profile page blur stuff (haven't done them) */

.profile-page {
  /* Additional styles for the profile page */
}

.profile-page.blurred {
  /* Styles for blurring the background */
  backdrop-filter: blur(10px);
}

.blur-it {
  filter: blur(3px);
  /* backdrop-filter: blur(5px); */
}
.blur-email {
  filter: blur(6px);
  /* backdrop-filter: blur(5px); */
}

/* button styles */
/* CSS */
.button-91 {
  color: #fff;
  padding: 15px 25px;
  background-color: #38D2D2;
  background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  font-weight: bold;
  font-size: 16px;

  border: 0;

  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  cursor: pointer;
}

/* CSS */
.button-71 {
  background-color: #0078d0;
  border: 0;
  border-radius: 56px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: system-ui,-apple-system,system-ui,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",sans-serif;
  font-size: 14px;
  font-weight: 600;
  outline: 0;
  padding: 16px 21px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100px;  
  overflow: hidden; /* Add this line to hide overflow */
}

.button-71:before {
  background-color: initial;
  background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
  /* br:125 */
  border-radius: 125px;
  content: "";
  height: 50%;
  left:4%;
  opacity: .5;
  position: absolute;
  top: 0;
  transition: all .3s;
  width: 92%;
}

.button-71:hover {
  box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
  transform: scale(1.05);
}
/* 72 is for homepage */
.button-72 {
  background-color: #0078d0;
  border: 0;
  border-radius: 56px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: system-ui,-apple-system,system-ui,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",sans-serif;
  font-size: 14px;
  font-weight: 600;
  outline: 0;
  padding: 16px 21px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 138px;
}

.button-72:before {
  background-color: initial;
  background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
  border-radius: 125px;
  content: "";
  height: 50%;
  left: 7%;
  opacity: .5;
  position: absolute;
  top: 0;
  transition: all .3s;
  width: 86%;
  /* z-index:-1; */
}

.button-72:hover {
  box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
  transform: scale(1.05);
}

/*  */
/* @media (min-width: 768px) {
  .button-71 {
    padding: 16px 48px;
  }
} */
/* NEON testing */
/* jean's button: */
.button-73 {
  background-color: #f5f5f5;
  border: 2px solid #f5f5f5;
  border-radius: 8px; /* Adjust the roundness to your preference */
  /* color: #333; */
  cursor: pointer;
  display: inline-block;
  font-family: "Segoe UI", Roboto, sans-serif;
  font-size: 14px;
  font-weight: 600;
  outline: 10;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  /* box-shadow: 0px 0px 7px 3px rgb(134, 212, 189);  */
  /* box-shadow: 0px 0px 7px 3px rgb(196, 139, 196);  */
  /* box-shadow: 0px 0px 7px 3px rgb(167, 216, 196);  */
  /* box-shadow: 0px 0px 7px 3px rgb(216, 167, 183);  */
  box-shadow: 0px 0px 7px 3px rgb(167, 216, 196); 

  /* transition: box-shadow .3s ease-in-out; */
  transition: background-color .3s ease-in-out,border .3s ease-in-out;
  z-index: -1;
}

.button-73:hover {
  background-color: #ffffff;
  color: #333;
  color: rgb(65, 47, 47);

  border: 2px solid #ffffff;
  /* box-shadow: 0px 0px 7px 3px rgb(217, 184, 230);  */
  /* border: 3px solid #ffffff; */
  font-family: "Segoe UI", Roboto, sans-serif;
  /* font-size: 14.5px; */
  font-weight: 600;
  transition: box-shadow .3s ease-in-out;
  box-shadow: 0px 0px 7px 3px rgb(167, 216, 196); 
  transition: background-color .3s ease-in-out, border .3s ease-in-out;
  ;

}

.button-73:before {
  /* content: ""; */
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px; /* Adjust to match the button's border-radius */
  background: linear-gradient(45deg, #f5f5f5, #f5f5f5);
  opacity: 0;

  transition: opacity 0.3s;
}

.button-73:hover:before {
  opacity: 1;
}

.neon-border {
  position: relative;
  /* border-bottom: 1px solid pink; */
  animation: neon 1.5s infinite alternate;
}

@keyframes neon {
  0% {
    opacity: 1;
    /* text-shadow: 0 0 5px pink; */
    text-shadow: 0 0 10px pink, 0 0 20px pink, 0 0 30px pink;
  }
  100% {
    opacity: 1;
    text-shadow: 0 0 10px pink, 0 0 20px pink, 0 0 30px pink;
  }
} 


.custom-button-left {
  position: relative;
  left: -125px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.2s;
  margin-top: 10px;
}
.custom-button-left:active {
  transform: scale(0.95); /* Slight scale down */
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Lighter shadow */
}
.custom-button-right {
  position: relative;
  right: -125px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.2s;
  margin-top: 10px;
}
.custom-button-right:active {
  transform: scale(0.95); /* Slight scale down */
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Lighter shadow */
}

/*  */
.profile-link {
  position: absolute;
  left: 14%;
  top: 1.5%;
  cursor: pointer;
}

.person-vcard {
  position: absolute;
  left: 15%;
  top: 4.5%;
  font-size: 20px;
  cursor: pointer;
}

.person-vcard:hover + .profile-link {
  text-decoration: underline;
  text-decoration-color: pink;
  text-decoration-thickness: 2px;
  z-index: 2;
}
/* loading spinner */

/* .loading-spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  border: 10px solid #3498db; 
  border-top: 10px solid transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1.5s linear infinite;
}

.loading-spinner img {
  width: 200px;
  z-index: 1;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} */
.gradient-background {
  background: linear-gradient(to right, #ff8a00, #da1b60);
}

.my-button {
  margin-bottom:10px;
  margin-left:10px;
  margin-right:10px;
  /* display: inline-block; */
  padding: 1px 10px;
  font-size: 16px;
  font-family: 'Cormorant Garamond', serif; /* Applying Cormorant Garamond font */
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f2f2f2; /* Grey background */
  color: #000; /* Black text */
  border: 2px solid #f2f2f2; /* Matching border for background color */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.my-button:hover {
  background-color: #ddd; /* Lighter grey on hover */
  border-color: #ddd;
}

.my-button:focus {
  outline: none;
}

.my-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.my-button:disabled {
  opacity: 0.6; /* Reduce opacity for disabled state */
  cursor: not-allowed;
  background-color: lightgrey; /* Lighter grey on hover */
  /* border-color: green; */

}

/* dfgdfgdfg */
.loading-spinner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
/* ;3498db */
.spinner {
  position: absolute;
  border: 20px solid #1a82c7;
   /* Blue border color, adjust as needed */
  border-top: 20px solid transparent;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  animation: spin 2s linear infinite;
  z-index: 1; /* Place the spinner behind the image */
}

.loading-spinner img {
  width: 200px; 
  height: 200px; 
  border-radius: 50%;
  object-fit: cover;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* reply */
.reply {
    display: flex;
    align-items: flex-end;
    margin-right: 2rem;
    flex-direction: column;
}
.reply-small {
  display: flex;
  align-items: flex-end;
  margin-right: 1rem;
  flex-direction: column;
  max-width:90vw;
}

/* Styles for the article container */
.profiles-container {
  cursor:pointer;
  display: flex;
  margin: 20px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}
.profiles-container:hover {
  transform: scale(1.02);
}
.another-container {
  cursor:pointer;
  display: flex;
  margin: 20px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  height:"200px";
}
.another-container:hover {
  transform: scale(1.02);
}
.profiles-image {
  /* height: 200px; 
  object-fit: cover; */
  width: 200px; 
  height: 200px; 
  border-radius: 50%;
  object-fit: cover;
}
.profiles-details {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 0 8px 8px 0;
  box-sizing: border-box;

  height: 250px;
}

.profiles-details h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.profiles-details p {
  color: #555;
  line-height: 1.5;
  margin-bottom: 10px;
}

.long-profiles-details {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 0 8px 8px 0;
  box-sizing: border-box;
  cursor:pointer;
  height: 200px;

  
/* overflow: auto; */
/* will-change: transform; */
/* overflow-y: scroll; */
text-overflow: ellipsis;
word-wrap: break-word;
}

.long-profiles-details h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.long-profiles-details p {
  color: #555;
  line-height: 1.5;
  margin-bottom: 10px;
}
.wrap-and-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Adjust the number of lines as needed */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
/* FILTER STYLES */
.filter-section {
  display: flex;
  align-items: center;
  /* margin-bottom: 20px; */
  margin-right: 20px;
  margin-left: 20px;
}

.filter-section p {
  margin-right: 10px;
}

.filter-section button {
  padding: 8px 16px;
  margin-right: 8px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f8f8f8;
  transition: background-color 0.3s;
}

.filter-section button:hover {
  background-color: #e0e0e0;
}

.filter-section button.active {
  background-color: #3498db;
  color: #fff;
  border-color: #3498db;
}

.upload-button {
  padding: 8px 16px;
  margin-right: 8px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f8f8f8;
  transition: background-color 0.3s;
}

.upload-button:hover {
  background-color: #e0e0e0;
}
.upload-button:disabled {
  opacity: 0.8; /* Reduce opacity for disabled state */
  cursor: not-allowed;
  background-color: lightgrey;
  /* border-color: green; */
}

.agenda-button {
  padding: 8px 16px;
  margin-right: 8px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f8f8f8;
  transition: background-color 0.3s;
}
.agenda-button:hover {
  background-color: #e0e0e0;
}
.agenda-button:disabled {
  background-color: #3498db;
  color: #fff;
  border-color: #3498db;
}

.grey-button {
  padding: 8px 16px;
  margin-right: 8px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f8f8f8;
  transition: background-color 0.3s;
}
.grey-button:hover {
  background-color: #e0e0e0;
}

/* .masonry-grid {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}
.masonry-sizer,
.masonry-item {
  box-sizing: border-box;
  flex: 1 0 calc(33.3333% - 20px);
  margin: 10px;
}
.masonry-sizer {
  width: 33.3333%;
  flex-grow: 0;
}
.masonry-item img {
  max-width: 100%;
  height: auto;
}
.masonry-item:first-child {
  flex: 2 0 calc(66.6667% - 20px); 
} */
.masonry-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

.masonry-sizer,
.masonry-item {
  box-sizing: border-box;
  /* flex: 1 0 calc(50% - 40px);  */
  margin: 10px; /* Adjust this value based on your gutter size */

}

.masonry-sizer {
  width: calc(33.3333% - 10px); /* Adjust the width of your columns */
  margin: 0 5px;
}

.masonry-item {
  width: 100%;
  margin-bottom: 10px; /* Adjust the vertical gap between items */
}
.masonry-item img {
  max-width: 30vw;
  height: auto;
}



/* NEW STYLES BELOW FOR ARTICLES: */

.article-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
  grid-gap: 10px;
  margin: 0px;
  margin-top: 35px;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}
.article-container {
  /* background-color: #ddd3d3; */

  display: flex;
  justify-content: center;
  margin-right: 10px;
  margin-bottom:0px;
  margin-left:10px;
  /* flex-direction: column; */
  /* max-width:30vw; */
  transition: transform 0.3s ease; /* Add transition effect */

}

.new-article-container {
  background-color: #e0e0e0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start; /* Align items to the start of the cross axis (top of the container) */
  max-width: 30vw;
  height: 100%; /* Ensure the container takes full height */

}

.article-image {
  /* width: 100%; */
  max-width: 100%;
  max-height: 40vh;
  object-fit: cover;
  margin-bottom: 20px;
  transition: all .3s ease;
}
.article-container:hover {
  transform: scale(1.02);
}

.the-article-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}


/*  */

.article-details {
  padding: 0px;
  background-color: #fff;
  /* border-radius: 0 8px 8px 0; */
  /* width: 200px; */
  max-height:250px;
}

.article-details h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.article-details p {
  /* color: #555; */
  line-height: 1.5;
  margin-bottom: 10px;
}

/* dsfsdfsdfsd break in the styles ok?  */

.read-more-button {
  background-color: #3498db;
  position: relative;

  color: #fff;
  padding: 10px 15px;
  /* border: 3px solid transparent; */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  border-bottom: 3px solid transparent;
}
.read-more-button:hover {
  background-color: #2980b9;
}
.read-more-button:disabled {
  background-color: #2980b9;
  /* border-bottom: 3px solid pink; */
  
}
.read-more-button:disabled::before {
  content: ''; /* Add an empty content to create a pseudo-element */
  position: absolute;
  bottom: -10px; /* Adjust the distance between the button and the underline here */
  left: 0;
  width: 100%;
  height: 5px; /* Adjust the thickness of the underline here */
  background-color: pink; /* Adjust the color of the underline here */
  border-radius: 0 0px 0px 0px; /* Adjust the border-radius for the pink underline */
  box-shadow: 0 3px 5px pink; 

}
.stories-button {
  margin: 10px;
  padding: 12px 20px;
  background-color: #35926b;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.stories-button:hover {
  background-color: #43a57c;
  cursor:pointer;
}
.stories-button:disabled {
  background-color: #43a57c;
  cursor:not-allowed;
  opacity:0.8;
}

.stories-select {
  margin: 10px;
  padding: 12px 20px;
  background-color: #35926b;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.stories-select:hover {
  background-color: #43a57c;
  cursor: pointer;
}

/* Style for the react-select dropdown indicator (arrow) */
.stories-select-indicator {
  /* color: #fff; */
}

/* Style for the react-select dropdown menu */
.stories-select-menu {
  background-color: #35926b;
  /* color: #fff; */
}

/* Style for the react-select option */
.stories-select-option {
  /* padding: 8px 20px; */
}

/* Style for the react-select option on hover */
.stories-select-option:hover {
  background-color: #43a57c;
}
.custom-dropdown {
  position: relative;
  display: inline-block;
}
/* Styles for the "Read More" button */
.read-more-button {
  background-color: #3498db;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.read-more-button:hover {
  background-color: #2980b9;
}

/*  */


/* playing with images made of text! */
.text-image-container {
  position: relative;
}

/* embed code */
.clickable-anchor {
  display: inline-block;
}

.disable-pointer-events {
  pointer-events: none;
}
/* this code means the image spins... */

/* .loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}

.loading-spinner img {
  width: 100px; 
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* .prof-blue {
  font-weight: bold;
  
  color: #007BFF;
  font-family: 'Helvetica Neue';
  line-height: 1.6;
} */
/* .prof-blue {
  font-size: 18px;
  color: black;
  font-family: 'cursive'; 
  line-height: 1.6;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); 
} */
.pulse-text {
  color: maroon; 
  font-family: 'Your Elegant Font', sans-serif; 
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.prof-blue {
  font-weight: 600; /* Adjust as needed */
  font-size: 20px; /* Adjust as needed */
  color: #444; /* Slightly lighter color for elegance */
  font-family: 'EB Garamond', serif; /* A refined serif font, you can choose others as well */
  line-height: 1.4;
  letter-spacing: 0.5px; /* Adjust as needed for refinement */
  text-transform: capitalize; /* Only the first letter in uppercase */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Subtle text shadow for depth */
}

/* agenda styles: */
.reunion-schedule {
  width:100%;
  max-width: 95vw;
  /* margin: 20px auto; */
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
}

.reunion-schedule h2 {
  color: #333;
  font-size: 24px;
  margin-top: 20px;
}

.reunion-schedule p {
  margin-bottom: 10px;
}

.reunion-schedule strong {
  font-weight: bold;
  color: #007BFF;
}

.reunion-schedule ul {
  list-style-type: disc;
  margin-left: 20px;
}

.reunion-schedule li {
  margin-bottom: 5px;
  color: #555;
}

.welcome-animation {
  overflow: hidden; /* Hide the overflow of the text */
  white-space: nowrap; /* Prevent the text from wrapping */
  animation: slideFromLeft 1.5s ease-in-out forwards; /* Animation settings */
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%); /* Start position: off the screen to the left */
  }
  to {
    transform: translateX(0); /* End position: on the screen */
  }
}